<div
  class="header p-4 bg-gray-75 flex justify-between cursor-pointer"
  (click)="isOpen = !isOpen">
  <div class="left-block">
    <div class="ev-h3">{{ product.name }}</div>
    <div class="product-id text-gray-600">#{{ product.id }}</div>
  </div>
  <div class="right-block flex items-center gap-4">
    <atom-button (click)="onAddArticle($event)">Add Article</atom-button>
    <atom-icon
      class="shrink-0"
      [iconId]="
        isOpen ? 'atom:core:arrow_up' : 'atom:core:arrow_down'
      "></atom-icon>
  </div>
</div>
<div class="px-4 py-1 font-bold bg-gray-200">
  Articles ({{ product.articles.length }})
</div>
@if (isOpen) {
  <div class="articles p-4 bg-gray-75">
    @if (!product.articles.length) {
      <p>No articles added yet</p>
    } @else {
      <div class="table grid grid-cols-6 gap-2 items-center">
        @for (title of columnTitles; track $index) {
          <div class="font-semibold font-sm">
            @if (title !== "EDIT") {
              {{ title }}
            }
          </div>
        }
        @for (article of product.articles; track article.distributorArticleId) {
          <div class="id ev-link" (click)="editArticle.next(article)">
            {{ article.distributorArticleId }}
          </div>

          <div class="name">{{ article.name }}</div>

          <div class="size">
            {{ article.packagingSize.value }}
            {{ article.packagingSize.unitOfMeasurement }}
          </div>

          <div class="price"
            >{{ article.grossPricePerUnit | evPrice }} /
            {{ article.packagingSize.unitOfMeasurement }}</div
          >

          <div class="status">
            {{ statusMapping[article.status] }}
          </div>

          <atom-button
            secondary
            size="small"
            (click)="editArticle.next(article)"
            >Edit</atom-button
          >
        }
      </div>
    }
  </div>
}
