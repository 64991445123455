<div class="ev-h2 mb-6">Product Management</div>
<div
  class="filters flex flex-wrap gap-4 items-end mb-6"
  [formGroup]="filterFormGroup">
  <atoms-input
    [label]="searchLabel"
    atomsControl2
    formControlName="searchTerm"></atoms-input>
  <atom-checkbox
    atomsControl
    formControlName="withArticles"
    [label]="withArticlesLabel"></atom-checkbox>
</div>
@if (productsStore.error()) {
  <p>Sorry, an error occured, please try again later.</p>
} @else if (productsStore.loading()) {
  <atom-progress-spinner></atom-progress-spinner>
} @else if (!$visibleProducts().length) {
  <p>No products found</p>
} @else {
  <div class="ev-h4 mb-2">Products ({{ $visibleProducts().length }})</div>

  <div class="products grid gap-2">
    @for (product of $visibleProducts(); track product.id) {
      <dp-product-accordion
        [product]="product"
        (addArticle)="onAddArticle(product)"
        (editArticle)="onEditArticle(product, $event)"></dp-product-accordion>
    }
  </div>
}

@if ($selectedProductMeta()) {
  <dp-edit-article-dialog></dp-edit-article-dialog>
}
